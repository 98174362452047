<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="400px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn          
          dark
          v-bind="attrs"
          v-on="on"
          class="d-flex" style="width: 100%; margin-bottom: 5px;" small color="secondary" outlined
          @click="initFields"
        >
          Save Tmpl.
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Create Adpoint Filtering Template</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <v-list>
            <v-list-item>
                <v-list-item-content>
                    <v-text-field 
                        label="Title" 
                        v-model="tmplname"
                        outlined                                                
                        :rules=[]
                    />
                </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-alert
                v-if="error"
                border="left"
                colored-border
                type="error"
                elevation="2"
            >
                {{error}}
            </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onSave"
            :disabled="saveDisabled"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'FilterTemplateSaveDialog',
    props: {
        saveHandler: Function,
        context: {
            default: () => {return {}},
            type: Object
        },
    },    
    data () {
      return {
        dialogm1: '',
        dialog: false,
        tmplname: '',        
        saveDisabled: false,
        error: '',
      }
    },  
    methods: {
        onSave: async function() {
            this.saveDisabled = true;
            if(this.saveHandler) {
                const rslt = await this.saveHandler(
                    {
                        title: this.tmplname
                    },
                    this.context                
                );
                if(rslt.error) {
                    this.error = rslt.error;
                    this.saveDisabled = false;
                }
                else {
                    this.dialog = false;
                }
            }              
            // this.$emit('save', { title: this.tmplname } );                        
        },
        initFields: function() {
            this.saveDisabled = false;
            this.tmplname = '';    
            this.error = '';
        }
    },
    // mounted() {
    //     this.saveDisabled = false;
    //     this.tmplname = '';
    // },
}
</script>

<style>

</style>